.dealRoom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: center;
}

.landingPageHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.errorMessage {
  display: flex;
  padding: 10px 0 0 10px;
  color: red;
  font-family: Nobile;
}

.headerFirstCol {
  display: flex;
  width: 50%;
}

.headingTextBox {
  display: flex;
  margin-left: 50px;
}

.headingText {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  border-bottom: 4px solid #82f5de;
}

.headerSecondCol {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.createAdminBox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.headerSecondCol Button {
  border-radius: 10px;
}

.landingPageBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0rem;
  padding: 1rem;
  justify-content: center;
  width: calc(100% - 2rem);
}

.tableBox {
  display: flex;
  margin-top: 1rem;
  border-radius: 1.25rem;
  width: 60%;
  box-shadow: 6px 6px 10px #d1d9e6;
  background-color: #fff;
}

.tableHeading {
  font-weight: 200;
}

.formFirstCol {
  display: flex;
  width: 35%;
}

.button {
  display: flex;
  justify-content: center;
  width: 105px;
  height: 48px;
  align-items: center;
  box-shadow: 6px 6px 10px #d1d9e6;
  background-color: #f5f5ff;
  border: 2px solid #fff;
  color: #283959;
  border-radius: 5rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
}

.formBox {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 45%;
}

.subHeading {
  font-family: Roboto;
  font-size: 25px;
  font-weight: 700;
  border-bottom: 4px solid #82f5de;
}

.formRow {
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: auto;
}

.filledBox {
  width: 100%;
}

.labelText {
  display: flex;
  margin: 20px 0px 8px 0;
  font-family: Nobile;
  font-weight: 400;
  color: #283959;
  font-size: 18px;
  margin-left: 0.25rem;
  line-height: 18px;
  /* max-width: 500px; */
  text-align: center;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f9fc;
  border: 2px solid #fff;
  border-radius: 30px;
  padding: 0px 10px !important;
  box-shadow: 5px 5px 10px#D1D9E6 inset;
}

.capTableInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15%;
  height: auto;
}

.capTableInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65%;
  align-items: center;
  background-color: #f4f9fc;
  border: 2px solid #fff;
  border-radius: 0.5rem;
  padding: 0px 10px !important;
  box-shadow: 5px 5px 10px#D1D9E6 inset;
}

.actionIcons {
  width: 40px;
  height: 40px;
  box-shadow: -6px -6px 10px #ffffff, 3px 2px 10px #d1d9e6;
  border-radius: 0.5rem !important;
  background-color: #ffffff !important;
  margin: 0.25rem !important;
}

.dealroomDocPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 6rem);
  margin: 1rem;
  padding: 2rem;
  background-color: #f7f8f9;
  border-radius: 1rem;
  box-shadow: 5px 5px 10px#D1D9E6 unset;
}

.dealroomDocPreviewBackButton {
  box-shadow: -5px -5px 25px 0px #ffffff;
  box-shadow: 5px 5px 25px 0px #3d32cf26;
  box-shadow: -5px -5px 5px 0px #fdfdff;
  box-shadow: 5px 5px 10px 0px #3d32cf26;
  border: 2px solid;
  border-image-source: linear-gradient(135.94deg, #ffffff 8.8%, #f5f5ff 91.36%);
  background: #f5f5ff;
  text-transform: none !important;
  border-radius: 1rem !important;
  color: #283959;
  min-width: 5.5rem !important;
}

@media screen and (max-width: 1043px) {

  .inputContainer,
  .capTableInputContainer {
    width: 20rem !important;
  }
}

@media screen and (max-width: 990px) {

  .inputContainer,
  .capTableInputContainer {
    width: 20rem !important;
  }
}

/* .MuiOutlinedInput-notchedOutline, .MuiSelect-select{
      border: none !important;
      outline: none !important;
  } */

.inputBox {
  margin-top: 8px;
  min-height: 30px;
  min-width: 10px;
  width: 100%;
  outline: none !important;
  border: none !important;
  margin-left: 0.25rem;
  border-radius: 5rem;
  font-size: medium;
  font-size: 16px;
  font-family: Nobile;
  background-color: #f4f9fc;
}

.inputBox:focus {
  outline: none !important;
  border: none !important;
}

.teamMemberForminputBox {
  margin-top: 8px;
  min-height: 40px;
  min-width: 10px;
  width: 100%;
  outline: none !important;
  border: none !important;
  margin-left: 0.25rem;
  border-radius: 5rem;
  font-size: medium;
  font-size: 16px;
  font-family: Nobile;
  background-color: #f8f8f800;
}

.teamMemberForminputBox:focus {
  outline: none !important;
  border: none !important;
}

.formContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.fundingFormBox {
  max-width: 549px;
  width: 100%;
}

.legalSupportFormBox {
  max-width: 653px;
  width: 100%;
}

.options {
  width: 96%;
  max-height: 50vh;
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  /* left: 40px; */
  top: 40px;
  border-radius: 4px;
  border: 2px solid #eff1f3;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.options ul {
  /* max-height: 140px; */
  overflow-y: auto;
  padding: 0px;
  margin: 0px;
}

.options ul li {
  border: 1px solid #fff;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
}

.options ul li:hover {
  border-color: #f2f3f5;
  background: #f2f3f5;
  font-weight: 600;
  cursor: pointer;
}

.administratorsFormBox {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.administratorsFormCol {
  width: 100px;
}

.addButton {
  margin-left: 20px;
  /* margin-bottom: 5px; */
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d32cf;
  cursor: pointer;
  max-width: 215px;
  width: 100%;
  padding: 12px 0;
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d32cf;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  margin-left: 20px;
  margin-bottom: 5px;
  cursor: pointer;
}

.addButton span {
  color: #fff;
  padding: 10px 30px;
  font-family: Nobile;
  font-size: 16px;
  font-weight: 700;
}

.submissionButtonBox {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
}

/* ############################################################### */
/* Payment Form page */

.paymentformContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem;
  width: 50%;
  color: #283959;
}

.subPaymentformContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payPageHeading {
  font-family: Nobile;
  font-size: 35px;
  font-weight: 500;
  line-height: 45px;
}

.paySubHeadingBox {
  margin: 1.5rem 0;
  max-width: 688px;
}

.buyOrSellSubHeading {
  padding: 0 122px;
}

.legalSupportSubHeadingBox {
  padding: 0 150px;
}

.paySubHeading {
  font-family: Nobile;
  font-size: 28px;
  font-weight: 500;
}

.payItemsBox {
  display: flex;
  flex-direction: row;
  width: 250px;
  justify-content: space-between;
  margin: 5px 0;
}

.payItemsBox span {
  font-family: Nobile;
  font-size: 18px;
  font-weight: 250;
}

.payButtonRow {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 300px;
}

/* connect page */

.connectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6.25rem 0rem 5rem 0rem;
  justify-content: center;
  width: 100%;
}

.connectBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1.5rem 1.2rem;
  width: 377px;
  height: 267px;
  text-align: center;
  border-radius: 1rem;
  cursor: pointer;
}

/* team member add form  */

.newTeamFormHeading {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.newTeamFormHeadingBox {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  width: 90%;
}

.newTeamFormHeadingBox span {
  font-family: Roboto;
  font-size: 25px;
  font-weight: 700;
}

.cancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecf0f3;
  padding: 10px 40px;
  border-radius: 10px;
  font-weight: 500;
}

.deligenceQuestion {
  color: #3d32cf;
  width: 154px;
  height: 24px;
  font-size: 14px;
  margin-top: 20px;
  font-weight: 600;
  line-height: 24px;
}

.uploadFileRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  padding: 10px 20px 10px 0;
  background-color: #f8f8f9;
  box-shadow: 10px 10px 15px 5px #d1d9e6;
  border-radius: 10px;
  margin: 10px 0px 0 0;
  /* margin-bottom: 40px; */
}

.tagDocContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.tagDocBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  margin: 5px;
  background-color: "#F8F8F9";
  box-shadow: -5px -5px 5px 0px #d1d9e6, -5px 5px 5px 0px #d1d9e6,
    10px 10px 15px 5px #d1d9e6;
  border-radius: 30px;
}

.tagDocBox label {
  font-family: Nobile;
  margin: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 15.78px;
  text-align: left;
}

.dueDeligenceSection {
  font-family: Nobile;
  font-weight: bold;
}