.mainContainer {
  width: 92%;
  /* background-color: #c35757; */
}

.firstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
}

.firstRow_Left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  column-gap: 20px;
}
.fineTune_btn {
  margin-left: auto;
  margin-right: 35px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: #FFF; */
  background-color: #f4f9fc;
  /* box-shadow: -10px -10px 10px #FFF; */
  border: 2px solid #fff;
  border-radius: 30px;
  padding: 0px 10px !important;
  box-shadow: 5px 5px 10px#D1D9E6 inset;
  /* box-shadow: 5px 5px 10px 0px #D1D9E6 inset, -5px -5px 10px 0px #FFF inset; */
}

@media screen and (max-width: 1043px) {
  .inputContainer {
    width: 20rem !important;
  }
}

@media screen and (max-width: 990px) {
  .inputContainer {
    width: 20rem !important;
  }
}

/* .MuiOutlinedInput-notchedOutline, .MuiSelect-select{
    border: none !important;
    outline: none !important;
} */

.inputBox {
  margin-top: 8px;
  min-height: 40px;
  min-width: 10px;
  width: 100%;
  outline: none !important;
  border: none !important;
  margin-left: 0.25rem;
  border-radius: 5rem;
  font-size: medium;
  font-size: 16px;
  font-family: Nobile;
  background-color: #f4f9fc;
}

.inputBox:focus {
  outline: none !important;
  border: none !important;
}

.headingtext {
  font-weight: 600;
  font-size: 40px;
  color: #283959;
  margin-left: 20px;
  font-family: sans-serif;
}

@media screen and (max-width: 480px) {
  .headingtext {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1090px) {
  .headingtext {
    font-size: 2rem;
    margin-left: 0px;
  }
}

@media screen and (max-width: 990px) {
  .headingtext {
    font-size: 2rem;
    margin-left: 0px;
  }
}

.button {
  display: flex;
  justify-content: center;
  width: 105px;
  height: 48px;
  align-items: center;
  box-shadow: 6px 6px 10px #d1d9e6;
  background-color: #f5f5ff;
  border: 2px solid #fff;
  color: #283959;
  border-radius: 5rem;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
}

/* ############################################################################################# */

/*---------- AIDataCreate -----------------*/

.AIDataCreatecontainer {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}

.AIDataCreate1stCol {
  display: flex;
  justify-content: center;
  width: 10%;
}

.AIDataCreate2ndCol {
  width: 85%;
  /* background-color: yellow; */
}

.newRequestStyles {
  width: 71%;
  margin-left: 10rem;
}

.whiteBox {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  padding: 15px 25px;
  background-color: #fff;
  border-radius: 30px;
  margin: 20px 20px;
  position: relative;
  box-shadow: 6px 6px 10px #d1d9e6;
}

.formRow {
  /* background-color: green; */
  width: auto;
  height: auto;
}

.filledBox {
  width: 400px;
}

.options {
  width: 230px;
  max-height: 140px;
  position: absolute;
  left: 40px;
  top: 54%;
  border-radius: 4px;
  border: 2px solid #eff1f3;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.options ul {
  max-height: 140px;
  overflow-y: auto;
  padding: 0px;
  margin: 0px;
}

.options ul li {
  border: 1px solid #fff;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
}

.options ul li:hover {
  border-color: #f2f3f5;
  background: #f2f3f5;
  font-weight: 600;
  cursor: pointer;
}

.labelText {
  display: flex;
  margin: 20px 0px 10px;
  font: Nobile;
  font-weight: 700;
  font-style: italic;
  color: #283959;
  font-size: 14px;
  margin-left: 0.25rem;
  line-height: 20px;
}

.labelNoticeText {
  font-size: 12px;
  color: #0085f5;
  font-weight: 800;
  margin-left: 0.25rem;
}

.inputContainerButtonDisabled {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d32cf;
  height: 38px;
  width: 100px;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  opacity: 0.4;
  /* margin: 10px 0; */
}

.inputContainerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d32cf;
  height: 38px;
  width: 100px;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;

  /* margin: 10px 0; */
}

.inputContainerButton:hover {
  background: linear-gradient(89.97deg, #48c2ca 0.02%, #1f9da5 99.25%);
}

.textarea {
  resize: none;
  margin-top: 8px;
  min-height: 40px;
  width: 100%;
  border-radius: 30px;
  font-size: medium;
  height: 200px;
  padding: 5px 10px;
  border: none;
  font-size: 14px;
  font-family: Nobile;
  background-color: #f4f9fc;
  overflow: auto;
}

.textarea:focus {
  border: none;
  resize: none;
  outline: none;
}

.questionBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 10px 20px 10px 0;
  background-color: "#F8F8F9";
  box-shadow: 10px 10px 15px 0px #d1d9e6;
  border-radius: 10px;
  margin-bottom: 10px;
  /* margin-bottom: 40px; */
}

.questionBoxText {
  font-family: Nobile;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}

.choiceBoxContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.box {
  width: 33.33%;
}

.hrline {
  width: 100%;
}

.selectedOptionsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.selectedOptionsBox {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #82f5de;
  padding: 2px 8px;
  border-radius: 20px;
  gap: 10px;
  margin: 5px;
  font-size: 12px;
  font-family: Nobile;
  font-weight: 500;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  width: 60px;
  justify-content: space-between;
}

.iconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  box-shadow: 3px 2px 10px 0px #d1d9e6, -2px -2px 6px 0px #fff;
}

.iconImg {
  width: 15px;
  margin-bottom: 0.1rem;
  cursor: pointer;
}

.tableCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tableCellTextOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tableContainer {
  width: 95%;
  overflow: hidden;
  margin-top: 20px;
  margin-left: 3rem;
}

.colSearchFilterBox {
  width: 17rem;
  max-height: 100vh;
  border-radius: 4px;
  border: 2px solid #eff1f3;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 10001;
  position: absolute;
  top: 83px;
  /* left: 5vw; */
}

.colSearchFilterBox ul {
  max-height: 200px;
  overflow-y: auto;
  padding: 0px;
  margin: 0px;
}

.searchFilterBox {
  width: 330px;
  max-height: 350px;
  position: absolute;
  left: 170px;
  top: 21%;
  border-radius: 4px;
  border: 2px solid #eff1f3;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 10001;
}

.searchFilterBox ul {
  max-height: 140px;
  overflow-y: auto;
  padding: 0px;
  margin: 0px;
}

.searchFilterBox ul li {
  border: 1px solid #fff;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
}

.searchFilterBox ul li:hover {
  border-color: #f2f3f5;
  background: #f2f3f5;
  font-weight: 600;
  cursor: pointer;
}

.filterBox {
  margin: 10px 10px 10px 20px;
}

.filterInput {
  width: 100%;
  height: 25px;
}

.optionsBox {
  width: 100%;
  border: 1px solid #000;
  margin-top: 5px;
}

.optionsBox li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.filterButtonRow {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 990px) {
  .colSearchFilterBox {
    width: 17rem;
    max-height: 100vh;
    border-radius: 4px;
    border: 2px solid #eff1f3;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
    position: absolute;
    top: 8vh;
    /* left: 5vw; */
  }
}

.filterButtonRow button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding: 10px 10px;
  margin: 5px 5px;
}

/* ==============AI Request Data=============== */
.aiRequestBox {
  width: 31%;
}

.reqChoiceGrayBox {
  display: flex;
  /* height: 65%; */
  width: 85%;
  height: 75%;
  flex-direction: column;
  padding: 0px 25px;
  background-color: #9daec6;
  border-radius: 15px;
  margin: 20px 20px;
  position: relative;
  box-shadow: 6px 6px 10px #d1d9e6;
  cursor: pointer;
}

.reqChoiceDef {
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  top: 0%;
  margin-bottom: 1.25rem;
}

.choiceRequestBoxContainer {
  display: flex;
  flex-wrap: wrap;
  width: 81%;
  margin: 0 6rem;
  justify-content: space-between;
  column-gap: 1px;
}

.reqQuestionBox {
  width: 100%;
}

.aiRequestTableContainer {
  width: 95%;
  overflow: hidden;
  margin-top: 20px;
  margin-left: 3rem;
}

.codeAndDelete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.codeAndDelete img {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
}

.codeAndDelete label {
  font-family: Nobile;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
}

.codeAndDelete span {
  font-family: Nobile;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
}

.codeAndDelete {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.codeAndDelete img {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
}

.codeAndDelete label {
  font-family: Nobile;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
}

.codeAndDelete span {
  font-family: Nobile;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
}

.whiteBoxTagQuestion {
  height: 30%;
  width: 70%;
  margin-left: 8rem;
  margin-bottom: 3rem;
}

.aiRequestButtons {
  display: flex;
  margin-left: 85%;
}

.buttonReportData {
  margin-left: 65%;
  margin-top: -40px;
  margin-bottom: 1rem;
}

.deleteIconImg {
  /* position: absolute; */
  width: 4%;
  cursor: pointer;
  margin-top: 0.15rem;
}

.saveIconImg {
  /* margin-left: 3rem; */
  margin-top: 0.3rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3d32cf;
  width: 100px;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .deleteIconImg {
    position: absolute;
    width: 7%;
    cursor: pointer;
    margin-top: 0.28rem;
  }

  .saveIconImg {
    /* margin-left: 3rem; */
    margin-top: 0.3rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d32cf;
    width: 100px;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
  }
}

.isExistingAiDataBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  /* background-color: red; */
}

.isExistingAiDataBox span {
  font-family: Nobile;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  text-align: left;
  /* margin-bottom: 20px; */
}

.isExistingAiDataBtnBox {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  width: 40%;
  justify-content: space-around;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #82f5de;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
